<template>
  <div class="main">
    <!-- <div class="horseRaceLamp">
      <el-carousel :interval="5000" arrow="always" height="232px">
        <el-carousel-item v-for="item in banner" :key="item.id">
          <img :src="item.thumb" @click="goToLink(item.link)" :style="{
            cursor: item.link ? 'pointer' : 'normal',
          }"/>
        </el-carousel-item>
      </el-carousel>
    </div> -->
    <indexSection1 :listDataCurrent="hotFormaleList" :banner="banner" />
    <indexSection2 :topicList="topicList" />
    <indexSection4 :list="activeList" />
    <indexSection3 :list="newsList" />
  </div>
</template>

<script>
// import vueSeamlessScroll from 'vue-seamless-scroll'
import indexSection1 from "@/views/pc/index/components/indexSection1";
import indexSection2 from "@/views/pc/index/components/indexSection2";
import indexSection3 from "@/views/pc/index/components/indexSection3";
import indexSection4 from "@/views/pc/index/components/indexSection4";
import { request } from "../../../network";
import { mapState } from "vuex";
import { getMemberId } from "@/assets/public/utils/token";
export default {
  data() {
    return {
      banner: [],
      newsList: [],
      countObj: {},
      hotFormaleList: [],
      topicList: [],
      activeList: [],
    };
  },
  components: {
    // vueSeamlessScroll,
    indexSection1,
    indexSection2,
    indexSection3,
    indexSection4,
  },
  created() {
    // this.getBanner()
  },
  mounted() {
    if (this.$route.fullPath === "/index/") {
      this.$router.push({
        path: "/index/index",
        query: {
          random: Math.floor(Math.random() * 100),
        },
      });
      setTimeout(() => {
        this.$router.push("/index");
      }, 100);
      // this.$router.push('/newIndex')
    }
    if (this.selectCity !== "") this.getIndexData(this.selectCity);
    this.getActivityList()
    if (!sessionStorage.getItem('pushSwitch')) {
      this.getPushSet();
    }
  },
  computed: {
    ...mapState({
      selectCity: (state) => state.menu.selectCity,
    }),
  },
  watch: {
    selectCity: {
      handler(val) {
        this.getIndexData(val);
      },
    },
  },
  methods: {
    async getBanner() {
      const { data: res } = await request({
        method: "GET",
        url: "/pcp/index/banner",
      });
      if (res.code !== 200) return;
      this.banner = res.data;
    },
    async getIndexData(city) {
      // const loading = this.$loading({
      //   lock: true,
      //   text: '加载中',
      //   spinner: 'el-icon-loading',
      //   background: 'rgba(0, 0, 0, 0.7)'
      // })
      const { data: res } = await request({
        method: "GET",
        url: "/pcp/index/getIndexData",
        params: {
          city: city,
        },
      });
      // setTimeout(() => {
      //   loading.close()
      // }, 100)
      if (res.code !== 200) {
        this.$message.error("获取数据异常");
      }
      this.banner = res.data.advertList;
      this.topicList = res.data.topicList;
      this.hotFormaleList = res.data.hotFormaleList;
      this.countObj = { ...res.data.count };
      this.newsList = res.data.newsList;
      this.$store.commit("personal/setCountObj", this.countObj);

    },
    async getActivityList() {
      const { data: res } = await request({
        method: "post",
        url: '/pcp/activity/homePageActivity',
        data: {
          memberId: getMemberId(),
          pageNo: 1,
          pageSize: 6
        }
      }, true);
      if (res.code !== 200) return this.$message.error("获取数据异常");
      this.activeList = res.data.list;
      // sessionStorage.setItem("topicList", JSON.stringify(this.activeList));
    },
    goToLink(link) {
      window.location.href = link;
    },
    async getPushSet() {
      const { data: res } = await request({
        method: "get",
        url: "/pcp/intelligentPush/pushSet",
        params: {
          id: getMemberId(),
        },
      });
      if (res.code !== 200) return this.$message.error("获取推送设置失败");
      let value = res.data === 1;
      sessionStorage.setItem('pushSwitch', value);
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  background-color: #ffffff;
}
.horseRaceLamp {
  margin-bottom: 50px;
}
.horseRaceLamp .el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}
.horseRaceLamp .el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}
.horseRaceLamp .el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
