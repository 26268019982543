<template>
  <div class="section4">
    <div class="flex row between">
      <div class="flex">
        <img src="../../../../assets/pc/images/index/titleLogo.png" alt="" />
        <div class="xinwenzhongxin">最新活动</div>
      </div>
      <router-link to="/eventServices/index" class="more"
        >查看更多 ></router-link
      >
    </div>
    <ul class="flex wrap between">
      <li
        class="flex row"
        v-for="(item, index) in list"
        :key="index"
        @click="goToNewsDetail(item.id)"
      >
        <img :src="item.activityImg" alt="" />
        <div>
          <div>{{ item.activityName }}</div>
          <div>
            活动时间：{{ timeIntercept(item.activityStartTime) }}至{{
              timeIntercept(item.activityEndTime)
            }}
          </div>
          <div>活动地点：{{ item.activityAdress }}</div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "indexsection4",
  props: {
    list: Array,
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    goToNewsDetail(id) {
      this.$router.push({ name: "activityDetails", query: { clickId: id } });
    },
    timeIntercept(time) {
      if (!time) {
        return;
      }
      let data = "";
      time.split("-").forEach((item, index) => {
        if (index === 1) {
          data += item + "月";
        } else if (index === 2) {
          data += item.split(" ")[0] + "日";
        }
      });
      return data;
    },
  },
};
</script>

<style lang="less" scoped>
.section4 {
  margin: 0 auto;
  margin-bottom: 65px;
  margin-top: 30px;
  width: 1200px;
}
.section4 .xinwenzhongxin {
  font-size: 20px;
  font-weight: bold;
  // color: #015293;
  // height: 31px;
  text-align: left;
  margin-left: 10px;
}
.section4 .more {
  font-size: 14px;
  color: rgba(21, 110, 208, 100);
}
ul {
  margin-top: 20px;
  li {
    width: 580px;
    height: 127px;
    cursor: pointer;
    border-radius: 8px;
    background-color: rgba(242, 245, 250, 100);
    margin-bottom: 10px;
    justify-content: left;
    border: 1px solid rgba(242, 245, 250, 100);
    img {
      width: 136px;
      height: 95px;
      border-radius: 8px;
      background-color: rgba(255, 255, 255, 100);
      margin-left: 10px;
    }
    > div {
      margin-left: 17px;
      margin-right: 20px;
      width: 400px;
      > div:nth-of-type(1) {
        color: rgba(0, 0, 0, 1);
        font-size: 18px;
        margin-bottom: 20px;
        display: -webkit-box; //将对象作为弹性伸缩盒子模型显示
        -webkit-box-orient: vertical; //从上到下垂直排列子元素(设置伸缩盒子的子元素的排列方式)
        text-overflow: ellipsis; //溢出用省略号显示
        overflow: hidden; //超出的文本隐藏
        -webkit-line-clamp: 1; //表明7行文本显示省略号,这里要根据自身需求进行设置
      }
      > div:nth-of-type(2) {
        color: rgba(0, 0, 0, 0.4);
        font-size: 16px;
        margin-bottom: 10px;
      }
      > div:nth-of-type(3) {
        color: rgba(0, 0, 0, 0.4);
        font-size: 16px;
        text-overflow: ellipsis; //溢出用省略号显示
        white-space: nowrap; //不换行
        overflow: hidden; //溢出隐藏
      }
    }
  }
  li:hover {
    // border: 1px solid #156ed0;
    box-shadow: 0 0 5px #93b1d1;
  }
}
</style>
